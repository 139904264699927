<template>
  <div>
    <div class="detailContent">
      <!-- 应用信息 -->
      <p class="detailTitle">{{ $t('SecurityMonitor.Prohibited.AppInfor')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{ $t('public.ApplicationName')}}：</span>
            <span class="detaildesc">{{ detailData.appName }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('public.ApplicationVersion')}}：</span>
            <span class="detaildesc">{{ detailData.appVersion }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Violation.SdkVersion')}}：</span>
            <span class="detaildesc" v-if="detailData.banContent.run">{{ detailData.banContent.run.app.sdkversion }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Violation.AppPackageName')}}：</span>
            <span class="detaildesc">{{ detailData.banContent.run.app.packagename }}</span>
          </div>
          <div class="group">
            <span class="detailLabel" style="vertical-align: top;">{{ $t('SecurityMonitor.Violation.AppMD5')}}：</span>
            <span class="detaildesc" style="vertical-align: top;">{{ detailData.banContent.run.app.md5 }}</span>
          </div>
        </div>
      </div>
      <!-- 基本信息 -->
      <p class="detailTitle">{{ $t('SecurityMonitor.Violation.BasicInformation')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{ $t('public.DeviceName')}}：</span>
            <span class="detaildesc">{{ detailData.deviceName }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('public.Name')}}：</span>
            <span class="detaildesc">{{ detailData.username }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Violation.EquipmentModel')}}：</span>
            <span class="detaildesc">{{ detailData.banContent.run.device.model }}</span>
          </div>
          <!-- <div class="group">
            <span class="detailLabel">设备厂商：</span>
            <span class="detaildesc"></span>
          </div>
          <div class="group">
            <span class="detailLabel">合规状态：</span>
            <span class="detaildesc"></span>
          </div> -->
          <div class="group">
            <span class="detailLabel">{{ $t('public.SystemVersion')}}：</span>
            <span class="detaildesc">{{ detailData.banContent.run.device.osversion }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Prohibited.DeviceIP')}}：</span>
            <span class="detaildesc">{{ detailData.banContent.run.device.ip }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{ $t('public.Username')}}：</span>
            <span class="detaildesc">{{ detailData.loginName }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Prohibited.UserDepartment')}}：</span>
            <span class="detaildesc">{{ detailData.groupFullname.substr(groupFullNameShowByIndex) }}</span>
          </div>
          <!-- <div class="group">
            <span class="detailLabel">设备型号：</span>
            <span class="detaildesc">{{ detailData.model }}</span>
          </div> -->
          <!-- <div class="group">
            <span class="detailLabel">设备越狱：</span>
            <span class="detaildesc"></span>
          </div> -->
          <div class="group">
                    <!-- 2024.7.10wjw说产品要设备IMEI根据设备类型展示；android: 设备IMEI; iOS: UDID;Linux:网卡Mac; Windows:网卡Mac -->
                    <span v-if="$common.getSystemName(detailData.banContent.run.device.osversion)=='Android'" class="detailLabel"
                    >{{ $t("public.IMEIAndroid") }}：</span
                  >
                  <span v-if="$common.getSystemName(detailData.banContent.run.device.osversion)=='iOS'" class="detailLabel"
                    >{{ $t("public.IMEIiOS") }}：</span
                  >
                  <span v-if="$common.getSystemName(detailData.banContent.run.device.osversion)=='Linux'" class="detailLabel"
                    >{{ $t("public.IMEIMac") }}：</span
                  >
                  <span v-if="$common.getSystemName(detailData.banContent.run.device.osversion)=='Windows'" class="detailLabel"
                    >{{ $t("public.IMEIMac") }}：</span
                  >
                  <span v-if="$common.getSystemName(detailData.banContent.run.device.osversion)=='Unknown'" class="detailLabel"
                    >{{ $t("public.IMEIUnknown") }}：</span
                  >
            <span class="detaildesc" style="vertical-align: top;" v-if="detailData.banContent.run.device.imei">{{ detailData.banContent.run.device.imei }}</span>
            <span class="detaildesc" style="vertical-align: top;" v-else> N/A </span>
          </div>
          <!-- <div class="group">
            <span class="detailLabel" style="display: block; float: left"
              >设备IMEI：</span
            >
            <span
              class="detaildesc"
              style="display: block; float: left; text-align: justify"
              >{{ detailData.banContent.run.device.imei }}</span
            >
          </div> -->
          <div class="group" style="display: flex;">
            <span class="detailLabel" style="display: block;">{{ $t('public.InternetMethod')}}：</span>
            <span class="detaildesc" style="display: block; float: left; text-align: justify">{{$filter.surfInterType(detailData.banContent.run.device.nettype) }}</span>
          </div>
        </div>
      </div>
      <!-- 位置信息 -->
      <p class="detailTitle">{{ $t('SecurityMonitor.Prohibited.PositionInfor')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Prohibited.Country')}}：</span>
            <span class="detaildesc" v-if="detailData.country">{{ detailData.country }}</span>
            <span class="detaildesc" v-else> N/A</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Prohibited.City')}}：</span>
            <span class="detaildesc" v-if="detailData.city">{{ detailData.city }}</span>
            <span class="detaildesc" v-else> N/A</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Prohibited.Province')}}：</span>
            <span class="detaildesc" v-if="detailData.province">{{ detailData.province }}</span>
            <span class="detaildesc" v-else> N/A</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('public.LatitudeLongitude')}}：</span>
            <span class="detaildesc" style="vertical-align: top;" v-if="detailData.banContent.run.device.longitude == '' || detailData.banContent.run.device.latitude == ''">N/A</span>
            <span class="detaildesc" style="vertical-align: top;" v-else>{{ detailData.banContent.run.device.longitude }},{{ detailData.banContent.run.device.latitude }}</span>
          </div>
        </div>
      </div>
      <div class="boxBot">
        <div class="group">
          <span class="detailLabel">{{ $t('SecurityMonitor.Prohibited.Address')}}：</span>
          <span class="detaildesc" v-if="detailData.banContent.run.device.location">{{ detailData.banContent.run.device.location }}</span>
          <span class="detaildesc" v-else> N/A</span>
        </div>
      </div>
      <!-- 封禁信息 -->
      <p class="detailTitle">{{ $t('SecurityMonitor.Prohibited.Blockedinfor')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Prohibited.Prohibitiontype')}}：</span>
            <span class="detaildesc" v-if="detailData.banType== 'S_BAN_DEVICE_UDID' || 'S_BAN_DEVICE_UDID_STD'">{{ $t('SecurityMonitor.Prohibited.Equipmentlockdown')}}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Prohibited.BanTime')}}：</span>
            <span class="detaildesc">{{
              detailData.time
            }}</span>
          </div>
          <div class="group">
            <span class="detailLabel" style="display: block; float: left; width: 28%">{{ $t('SecurityMonitor.Prohibited.Hitstrategy')}}：</span>
            <span class="detaildesc" style="display: block; float: left; text-align: justify">{{ detailData.banContent.info.rule }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel"></span>
            <span class="detaildesc"></span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('public.ReportingTime')}}：</span>
            <span class="detaildesc">{{
              detailData.uploadTime
            }}</span>
          </div>
        </div>
      </div>
      <div class="boxBot">
        <div class="group" style="width: 100%">
          <div class="detailLabel" style=" float: left;
                height: 24px;
                line-height: 24px;
                text-align: left;
                pading-right: 5px;
                width:13%;
              ">
            <span>{{ $t('SecurityMonitor.Prohibited.JudgmentSteps') }}：</span>
          </div>

          <!-- <p v-if="detailData.type == '锁屏密码未开启'|| detailData.type == '系统时间异常' ||detailData.type == '截屏阻断解除'">
            <span v-if="detailData.type == '锁屏密码未开启'">1)</span>
            <span v-if="detailData.type == '系统时间异常'">2)</span>
            <span v-if="detailData.type == '截屏阻断解除'">3)</span>
            {{
          detailData.type }}</p> -->
          <div style="float: left;
               width: 78%;
               height: inherit;
               text-align: left;
               padding-right: 5px;
              " class="detaildesc">
            <p v-for="(e, i) in detailData.banContent.info.decide" :key="i" style="
                 width: 100%;
                 display: inline-block;
                 overflow: hidden;
                 color: #606266;
                 margin-left: 0;
                 line-height: 24px;
                 word-wrap: break-word;
                 word-break: break-all;
                 color:#606266
                ">
              {{ i + 1 }}、{{ e }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'detail',

  props: {
    detailData: {
      type: Object,
      default: {}
    }
  },

  data() {
    return {
      groupFullNameShowByIndex: '',
    }
  },

  mounted() {
    console.log(this.detailData, '全部数据')
    console.log(this.local, '部分数据')
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
  },

  methods: {}
}
</script>

<style lang="scss" scoped>
.detailContent {
  width: 100%;

  // height: 469px !important;
  // overflow: auto !important;
  .detailTitle {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 700;
    height: 24px;
    line-height: 24px;
  }

  .detailbox {
    width: 100%;
    display: flex;
    font-size: 12px;

    .boxLeft {
      width: 50%;
      padding-left: 68px;
      line-height: 24px;

      .group {
        margin-top: 8px;

        .detailLabel {
          width: 44%;
          text-align: left;
        }

        .detaildesc {
          width: 56%;
        }
      }
    }

    .boxRight {
      width: 50%;
      line-height: 24px;

      .group {
        margin-top: 8px;

        .detailLabel {
          width: 23%;
          text-align: left;
        }

        .detaildesc {
          width: 67%;
          display: inline-block;
          overflow-y: visible;
        }
      }
    }
  }

  .boxBot {
    width: 100%;
    padding-left: 68px;
    font-size: 12px;

    .group {
      margin-top: 8px;

      .detailLabel {
        width: 22%;
        text-align: left;
        padding-right: 5px;
      }

      .detaildesc {
        width: 78%;
        display: inline-block;
      }
    }
  }
}

.detailContent .boxBot .group .detailLabel[data-v-70181544] {
  width: 13%;
}
</style>