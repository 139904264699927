<template>
  <div class="SensitiveMonitor">

      <el-row :gutter="4" class="mt15" style="display: flex">
        <el-col :span="3">
          <!-- 起始日期 -->
          <el-date-picker :clearable="false" size="small" style="width: 100%" v-model="formInline.startTime" type="date" :placeholder="$t('SecurityMonitor.Violation.SelectDate')" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" @change="changeDate">
          </el-date-picker>
        </el-col>
        <el-col :span="0.5">
          <span style="line-height: 30px; color: #dcdfe6">~</span>
        </el-col>
        <el-col :span="3">
          <el-date-picker :clearable="false" size="small" style="width: 100%" v-model="formInline.endTime" type="date" :placeholder="$t('SecurityMonitor.Violation.SelectDate')" value-format="yyyy-MM-dd" :picker-options="pickerOptions1" @change="changeDate">
          </el-date-picker>
        </el-col>
        <select-input :options="devNameOptions" @selectSearchChange="selectSearchChange" @searchList="onSubmit"></select-input>
        <el-col :span="3">
          <el-button size="small" type="primary" @click="advancedBtn">{{ $t('public.Derive') }}</el-button>
        </el-col>
      </el-row>
      <!-- 点击选中只显示对应内容 -->
      <el-row :gutter="4" class="elRow"  style="position: relative">
      <div style="
          display: inline-block;
          vertical-align: middle;
          position: absolute;
          right: 5px;
          top: -32px;
          cursor: pointer;
        ">
        <!-- 弹出框 -->
        <el-popover trigger="click" popper-class="LinkageTable">
          <el-checkbox-group @change="tableTitleTime" v-model="checkList">
            <el-checkbox v-for="(item, index) in tradeSelections" :key="index" :label="$t(`SecurityMonitor.Prohibited.Tablelinkage.${item.eng}`)" :checked="item.isChecked" :disabled="item.flag" @click.native="sss(item.eng, index, item.flag)">{{ $t(`SecurityMonitor.Prohibited.Tablelinkage.${item.name}`) }}</el-checkbox>
          </el-checkbox-group>
          <img slot="reference" src="@/images/showConfig.png" alt="" style="
              width: 20px;
              height: 20px;
            " />
        </el-popover>
      </div>
      <!-- 表格 -->
      <!-- {{this.bancontinue.run.device.osversion }} -->
      <el-table size="mini" ref="myTable" :data="tableData" stripe fit tooltip-effect="dark" class="tableClass" @sort-change="sort" :row-style="iRowStyle" :cell-style="iCellStyle" :header-row-style="iHeaderRowStyle" :header-cell-style="{ background: '#f7f8fa', color: '#606266', padding: '0px' }" :default-sort="{ prop: 'CREATETIME', order: 'ascending' }">
        <template>
          <el-table-column v-if="$filter.nameFilter(formThead,tradeSelections)">
            <el-table-column :show-overflow-tooltip="true" width="120" v-for="(item, index) in formThead" :key="index" :label="item.name">
              <template slot-scope="scope">
                {{ scope.row[item.eng] }}
              </template>
            </el-table-column>
          </el-table-column>
        </template>
        <el-table-column :show-overflow-tooltip="true" fixed width="120" :label="$t('public.DeviceName')" prop="DEVIVENAME" v-if="tradeSelections[tradeSelections.length - 18].isChecked">
          <template slot-scope="scope">
            {{ scope.row.deviceName }}
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" fixed width="120" :label="$t('SecurityMonitor.Prohibited.Prohibitiontype')" prop="TYPE" v-if="tradeSelections[tradeSelections.length - 17].isChecked">
          <!-- 设备封禁 -->
          <template slot-scope="scope" v-if="scope.row.banType == 'S_BAN_DEVICE_UDID' || 'S_BAN_DEVICE_UDID_STD'">
            {{ $t('SecurityMonitor.Prohibited.Equipmentlockdown') }}
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" :label="$t('public.ApplicationName')" prop="NAME" v-if="tradeSelections[tradeSelections.length - 16].isChecked">
          <template slot-scope="scope">
            {{ scope.row.appName }}
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" :label="$t('public.ApplicationVersion')" prop="VERSION" v-if="tradeSelections[tradeSelections.length - 15].isChecked">
          <template slot-scope="scope">
            {{ scope.row.appVersion }}
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" :label="$t('public.SystemVersion')" v-if="tradeSelections[tradeSelections.length - 14].isChecked">
          <template slot-scope="scope" >
            <span v-if="scope.row.banContent.run.device.osversion==''">N/A</span>
            <span  v-else>{{ scope.row.banContent.run.device.osversion }}</span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" :label="$t('public.Position')" v-if="tradeSelections[tradeSelections.length - 13].isChecked">
          <template slot-scope="scope">
            <span v-if="scope.row.banContent.run.device.location==''">N/A</span>
            <span  v-else> {{scope.row.banContent.run.device.location }}</span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" :label="$t('public.LatitudeLongitude')" v-if="tradeSelections[tradeSelections.length - 12].isChecked">
          <template slot-scope="scope">

            <span class="detaildesc" v-if="scope.row.banContent.run.device.longitude == '' || scope.row.banContent.run.device.latitude == ''">N/A</span>
            <span class="detaildesc" v-else>{{ scope.row.banContent.run.device.longitude }},{{ scope.row.banContent.run.device.latitude }}</span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" :label="$t('public.InternetMethod')" prop="nettype" v-if="tradeSelections[tradeSelections.length - 11].isChecked">
          <template slot-scope="scope">
            {{ $filter.surfInterType(scope.row.banContent.run.device.nettype) }}
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" :label="$t('public.ExternalIP')" prop="outip" v-if="tradeSelections[tradeSelections.length - 10].isChecked">
          <template slot-scope="scope">
            <span v-if="scope.row.banContent.run.device.outip==''">N/A</span>
            <span  v-else>{{ scope.row.banContent.run.device.outip }}</span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" :label="$t('public.IntranetIP')" prop="ip" v-if="tradeSelections[tradeSelections.length - 9].isChecked">
          <template slot-scope="scope">
            <span v-if="scope.row.banContent.run.device.ip==''"> N/A</span>
            <span  v-else>{{ scope.row.banContent.run.device.ip }}</span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" label="WIFI-SSID" prop="wifissid" v-if="tradeSelections[tradeSelections.length - 8].isChecked">
          <template slot-scope="scope">
            <span v-if="scope.row.banContent.run.device.wifissid==''"> N/A</span>
            <span  v-else>{{ scope.row.banContent.run.device.wifissid }}</span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" label="WIFI-MAC" prop="wifimac" v-if="tradeSelections[tradeSelections.length - 7].isChecked">
          <template slot-scope="scope">
            <span v-if="scope.row.banContent.run.device.wifimac==''">N/A</span>
            <span  v-else>{{ scope.row.banContent.run.device.wifimac }}</span>
          </template>
        
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" :label="$t('public.Username')" prop="LOGINNAME" v-if="tradeSelections[tradeSelections.length - 6].isChecked">
          <template slot-scope="scope">
            {{ scope.row.loginName }}
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" :label="$t('public.Name')" prop="username" v-if="tradeSelections[tradeSelections.length - 5].isChecked">
          <template slot-scope="scope">
            {{ scope.row.username }}
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" :label="$t('public.Section')" prop="groupFullname" v-if="tradeSelections[tradeSelections.length - 4].isChecked">
          <template slot-scope="scope">
            {{ scope.row.groupFullname.substr(groupFullNameShowByIndex) }}
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="140" :label="$t('SecurityMonitor.Prohibited.BanTime')" prop="TIME" v-if="tradeSelections[tradeSelections.length - 3].isChecked">
          <template slot-scope="scope">
            {{ scope.row.time  }}
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="140" :label="$t('public.ReportingTime')" prop="UPLOADTIME" v-if="tradeSelections[tradeSelections.length - 2].isChecked">
          <template slot-scope="scope">
            {{ scope.row.uploadTime }}
          </template>
        </el-table-column>

        <el-table-column fixed="right" :label="$t('public.Controls')">
          <template v-slot="{row}">
            <span  size="small" @click="details(row)" style="color: #d38a08;cursor: pointer;">{{ $t('public.Details') }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination :page="currentPage" :limit="pageSize" :total="total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList"></pagination>
    </el-row>
    <!-- 详情弹框 -->
    <Eldialog @handleClose="handleClose" :title="$t('public.Details')" :dialogVisible="outerVisible" :flagbtn="false" :num="'470px'">
      <InterdicDetail :detailData="detailData" />
    </Eldialog>

  </div>
</template>
<script>
import selectInput from "@/components/selectInput"
import options from '@/utils/country-level3-data' //三级城市数据
import Eldialog from '@/components/elDialog/index.vue'
import pagination from '../../components/pagination/page'
import InterdicDetail from './components/InterdicDetail.vue'
import { set } from 'vue'
export default {
  components: { selectInput,Eldialog, pagination, InterdicDetail },

  data() {
    return {
      key: 1, //table  key
      arrLI: {},
      total: 0,

      flag: 1,
      props: { multiple: true },
      number: 0,
      number1: 0,
      selectVal:{
        deviceName:'',
        InputText:""
      },
      pageSize: 10, //每页显示条数
      tbodyData: [], // 配置项数据
      seleShift: 'deviceName', // 选中下拉框转译
      checkList: [], //选中的选项eng数组
      formThead: [], //渲染表头
      orderRule: 'DESC', //排序规则ASC、DESC
      tableData: [], //列表data
      detailData: [], //详情data
      formInline: {
        startTime: '', // 选择日期
        endTime: '',

        reason: '', //下拉
        location: [],
        deviceName: '设备名称',

        devName: '', // 设备名称
        input: ''
      },
      formCustom: {
        Detail: '', //详细地址信息
        DetailMap: '', //城市地址详细地址拼接
        address: '' //省份城市
      },

      currentPage: 1, //当前页
      orderColume: 'upload_time', //排序字段
      placeholder: '设备名称',
      outerVisible: false,

      bancontinue: {},
      bancontinues: [], //详情data
      devNameOptions: [
        { value: 'appName', label: '应用名称' },
        { value: 'appVersion', label: '应用版本' },
        { value: 'deviceName', label: '设备名称' },
        { value: 'loginName', label: '用户名' },
        { value: 'username', label: '姓名' },
        { value: 'groupFullname', label: '部门' }
      ], //设备名称
      pickerOptions1: {},
      pickerOptions0: {},

      tradeSelections: [
        { eng: 'deviceName', name: '设备名称', isChecked: true, flag: true },
        { eng: 'banType', name: '封禁类型', isChecked: true, flag: true },
        { eng: 'appName', name: '应用名称', isChecked: true, flag: true },
        { eng: 'appVersion', name: '应用版本', isChecked: true, flag: false },
        { eng: 'osversion', name: '系统版本', isChecked: true, flag: false },
        { eng: 'location', name: '位置', isChecked: true, flag: false },
        {
          eng: 'longitudeAndLatitude',
          name: '经纬度',
          isChecked: false,
          flag: false
        },
        { eng: 'netType', name: '上网方式', isChecked: false, flag: false },
        { eng: 'outIp', name: '外网IP', isChecked: true, flag: false },
        { eng: 'ip', name: '内网IP', isChecked: false, flag: false },
        { eng: 'wifiSsid', name: 'WIFI-SSID', isChecked: false, flag: false },
        { eng: 'wifiMac', name: 'WIFI-MAC', isChecked: false, flag: false },
        { eng: 'loginName', name: '用户名', isChecked: true, flag: false },
        { eng: 'username', name: '姓名', isChecked: false, flag: false },
        { eng: 'groupFullname', name: '部门', isChecked: true, flag: false },
        { eng: 'time', name: '封禁时间', isChecked: true, flag: false },
        { eng: 'uploadTime', name: '上报时间', isChecked: true, flag: true },
        { eng: 'decide', name: '操作', isChecked: true, flag: true }
      ], // isChecked：true选中(默认)false不选中    flag：true是禁用(不可编辑)false不禁用(可编辑)
      cityOptions: options, // 城市数据
      selectedOptions: [], //存放默认值
      tradeSelections1: [],
      groupFullNameShowByIndex: '' //用户部门截取的下标ffff
    }
  },

  watch: {
    checkList(val) {
      this.formThead = this.tradeSelections.filter(
        (i) => val.indexOf(i.eng) >= 0
      )
      this.key - this.key + 1
    }
  },

  created() {},

  //取token
  beforeMount() {
    const token = sessionStorage.getItem('username')
  },

  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
    if (this.$route.query.name) {
      this.MingTime()
    } else {
      this.getDataList()
    }
  },
  updated() {
    // myTable是表格的ref属性值
    if (this.$refs.myTable && this.$refs.myTable.doLayout) {
      this.$refs.myTable.doLayout()
    }
  },
  methods: {
    // 排序@sort-change="sort"
    sort(column) {

      if (column.order === 'ascending') {
        this.orderRule = 'ASC'
      } else if (column.order === 'descending') {
        this.orderRule = 'DESC'
      }
      this.orderColume = column.prop
      this.onSubmit() //再次调用查询接口
    },
    // 详情关闭按钮
    handleClose() {
      this.outerVisible = false
    },
    Day() {
      // 安全态势的跳转
      this.formInline.startTime = this.$route.query.dataDay.split(' ')[0]
      console.log(this.formInline.startTime, '当日')
      this.formInline.endTime = this.$route.query.dataDay.split(' ')[0]
      console.log(this.formInline.endTime, '当日')
      this.onSubmit() // this.changeDate();
    },
    Mounth() {
      // 安全态势的跳转
      this.formInline.startTime = this.$route.query.dataMounth.split(' ')[0]
      this.formInline.endTime = this.$route.query.dataDay.split(' ')[0]
      this.onSubmit()
    },
    card() {
      this.formInline.startTime = this.$route.query.starttime.split(' ')[0]
      this.formInline.endTime = this.$route.query.dataDay.split(' ')[0]
      // this.onSubmit()
    },
    // 默认显示当天时间
    getdatatime() {
      //默认显示今天
      return new Date().getTime()
    },
    selectSearchChange(){

    },
    MingTime() {
      // 安全态势的跳转
      if (
        this.$route.query.starttime == '00' ||
        this.$route.query.starttime == '01' ||
        this.$route.query.starttime == '02' ||
        this.$route.query.starttime == '03' ||
        this.$route.query.starttime == '04' ||
        this.$route.query.starttime == '05' ||
        this.$route.query.starttime == '06' ||
        this.$route.query.starttime == '07' ||
        this.$route.query.starttime == '08' ||
        this.$route.query.starttime == '09' ||
        this.$route.query.starttime == '10' ||
        this.$route.query.starttime == '11' ||
        this.$route.query.starttime == '12' ||
        this.$route.query.starttime == '13' ||
        this.$route.query.starttime == '14' ||
        this.$route.query.starttime == '15' ||
        this.$route.query.starttime == '16' ||
        this.$route.query.starttime == '17' ||
        this.$route.query.starttime == '18' ||
        this.$route.query.starttime == '19' ||
        this.$route.query.starttime == '20' ||
        this.$route.query.starttime == '21' ||
        this.$route.query.starttime == '22' ||
        this.$route.query.starttime == '23' ||
        this.$route.query.starttime == '24'
      ) {
        this.formInline.startTime = this.UTCformat(this.getdatatime()).split(
          ' '
        )[0]
        this.formInline.endTime = this.UTCformat(this.getdatatime()).split(
          ' '
        )[0]

      } else {
        this.formInline.startTime = this.$route.query.starttime.split(' ')[0]
        this.formInline.endTime = this.$route.query.dataDay.split(' ')[0]
      }
      this.onSubmit()
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage = page
      console.log(this.currentPage, '一页也')
      this.pageSize = limit
      console.log(this.pageSize, '总过条数')
      // 调用查询方法
      this.onSubmit(this.selectVal)

    },
    tableTitleTime(i, k) {

    },
    sss(index, key, event) {

      if (event == true) {
        return false
      }
      if (this.number == 1) {
        if (
          index == 'deviceName' ||
          index == 'banType' ||
          index == 'appName' ||
          index == 'osversion' ||
          index == 'appVersion' ||
          index == 'location' ||
          index == 'longitudeAndLatitude' ||
          index == 'netType' ||
          index == 'outIp' ||
          index == 'ip' ||
          index == 'wifiSsid' ||
          index == 'wifiMac' ||
          index == 'username' ||
          index == 'loginName' ||
          index == 'groupFullName' ||
          index == 'groupFullname' ||
          index == 'time' ||
          index == 'uploadTime'
        ) {
          this.tradeSelections[key].isChecked =
            !this.tradeSelections[key].isChecked
        }
        this.number = 0
      } else {
        this.number = 1
      }
    },

    changeDate() {
      let start = new Date(this.formInline.startTime).getTime()
      let end = new Date(this.formInline.endTime).getTime()
      this.pickerOptions0 = {
        disabledDate: (time) => {
          if (end != '') {
            return time.getTime() > Date.now() || time.getTime() > end
          }
          return time.getTime() > Date.now()
        }
      }
      this.pickerOptions1 = {
        disabledDate: (time) => {
          return (
            time.getTime() < start - 24 * 60 * 60 * 1000 ||
            time.getTime() > Date.now()
          )
        }
      }
    },
        //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'padding:3px 0px'
    },
    // 列表
    async getDataList(val) {
      // 判断日期
      if (this.$route.query.fl == true) {
        this.Day()
      } else if (this.$route.query.fl == false) {
        this.Mounth()
      } else if (this.$route.query.card == true) {
        this.card()
      } else {
        // 原代码日期
        this.formInline.startTime = this.$common.getAnyMonth('-', -1)
        this.formInline.endTime = this.$common.getDate()
      }
      if (this.$route.query.cli == true) {
        this.MingTime()
      }
      this.changeDate()
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        customKey: val?val.deviceName:'',
        customVal: val?val.InputText:''
      }

      const res = await this.$axios.post(
        '/httpServe/appBan/getDataInfo',
        parma,
        true
      )
      console.log(res.data.content,"res.data.content")
      var list =[]
      res.data.content.forEach((item) => {
        // obj = { ...item }
        item.banContent = eval('(' + item.banContent + ')')
        list.push(item)
      })
      this.tableData=[...new Set(list)]
      this.total = res.data.total
      console.log(this.tableData,res.data.content,"------------------")
      for (var i = 0; i < this.tableData.length; i++) {
        // 对于为空的字段，显示N/A
        var obj2 = this.tableData[i]
        for (var key in obj2) {
          if (obj2[key] == null || obj2[key] == '') {
            obj2[key] = 'N/A'
          }
        }
      }

    },
    changeName(item) {
      this.placeholder = item
      this.devNameOptions.forEach((k) => {
        if (item == k.label) {
          this.seleShift = k.value
          console.log(this.seleShift)
        }
      })
    },
    //查询
    async onSubmit(val) {
      this.selectVal = val
      if (this.$route.query.card == true) {
        this.card()
      }

      let location = null
      let selectedOptions = this.formInline.location
      if (selectedOptions) {
        location = []
        selectedOptions.forEach((element) => {
          location.push(element.flat().join('/'))
        })
      }
      var a = JSON.stringify(this.formInline.location)
      var b = JSON.parse(a)

      var arr = []
      if (this.formInline.location.length > 0) {
        arr = b[0].join('/')
      }
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        customKey: val?val.deviceName:'',
        customVal: val?val.InputText:''
      }
      console.log(parma, '列表查询onsubmit')
      const res = await this.$axios.post(
        '/httpServe/appBan/getDataInfo',
        parma,
        true
      )
      console.log(res.data.content,"res.data.content")
      var list =[]
      res.data.content.forEach((item) => {
        // obj = { ...item }
        item.banContent = eval('(' + item.banContent + ')')
        list.push(item)
      })
      this.tableData=[...new Set(list)]
      this.total = res.data.total
      console.log(this.tableData,res.data.content,"------------------")
      for (var i = 0; i < this.tableData.length; i++) {
        // 对于为空的字段，显示N/A
        var obj2 = this.tableData[i]
        for (var key in obj2) {
          if (obj2[key] == null || obj2[key] == '') {
            obj2[key] = 'N/A'
          }
        }
      }
    },

    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.getDataList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getDataList()
    },
    //导出
    async advancedBtn() {
      let arrOut = []
      this.tradeSelections.forEach((item) => {
        if (item.isChecked == true && item.name != this.$t('public.Controls')) {
          arrOut.push(item.eng) //去重
          this.tradeSelections1 = [...new Set(arrOut)]

        }
      })
      let token2 = sessionStorage.getItem('Authorization')
        ? sessionStorage.getItem('Authorization')
        : 'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMwMjMzNzUsInVzZXJJZCI6MSwiaWF0IjoxNjMzMDAxNzc1fQ.hSF4bNDHVFZR2h4R0osNBF56ChR_l03mwx9QYaS1E0JDtIFlme3dP8oktCjEIaGi4QzXutLRMmjFWxuDNtaMMA'

      let userString = decodeURIComponent(
        escape(window.atob(token2.split('.')[1]))
      )


      let groupId = JSON.parse(userString).userId

      this.userId = groupId

      let location = null
      let selectedOptions = this.formInline.location
      if (selectedOptions) {
        location = []
        selectedOptions.forEach((element) => {
          location.push(element.flat().join('/'))
        })
      }
      var a = JSON.stringify(this.formInline.location)
      var b = JSON.parse(a)

      var arr = []
      if (this.formInline.location.length > 0) {
        arr = b[0].join('/')
      }

      if (this.$route.query.fl == true) {
        this.Day()
      } else if (this.$route.query.fl == false) {
        this.Mounth()
      }
      if (this.$route.query.cli == true) {
        this.MingTime()
      }
      if (this.$route.query.card == true) {
        this.card()
      }
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.total,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        // reason: this.formInline.reason,
        // location: location,
        // deviceName: '',
        //  
        customKey: this.selectVal.deviceName?this.selectVal.deviceName:this.seleShift,
        customVal: this.selectVal.InputText?this.selectVal.InputText:this.formInline.input,
        exportField: this.tradeSelections1
      }
      const res = await this.$axios.postExport(
        '/httpExport/appBan/exportExcel',
        parma,
        true,
        this.userId
      )
      const link = document.createElement('a')
      console.info(new Date())

      // let fileName = res.headers["content-disposition"].split("=")[1];
      var fileName = 'exportAppBan_' + Date.parse(new Date()) + '.xls'

      let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', decodeURI(fileName))
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    // 详情
    details(info) {
      console.log(info,"info")
      this.outerVisible = true
      this.detailData = info
      console.log(this.detailData,"detailData");
    },
    //配置项监听事件
    tableTitleTime(event) {

    }
  }
}
</script>

<style lang="scss" scoped>
.SensitiveMonitor {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
}
.elRow {
  margin-bottom: 10px;
}

.location {
  &::v-deep {
    & ::placeholder {
      color: #606266;
    }
  }
}
@-moz-document url-prefix() {
  .location {
    &::v-deep {
      & ::placeholder {
        color: #606266;
        opacity: 1 !important; /*火狐默认有opacity:0.4; */
      }
    }
  }
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 130px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 140px;
}
.el-form-item__content {
  width: 100%;
  /* display: flex; */
  margin: 0 !important;
}

::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
.detailContent .boxBot .group .detailLabel[data-v-70181544] {
  width: 13%;
}
/* 设置高优先，以覆盖内联样式 Table组件固定列底部有一条白线
::v-deep  .el-table__fixed{
  height: 100vh !important;
}
::v-deep  .el-table__fixed-right{
  height: 100vh !important;
}*/
::v-deep .el-table__fixed::before{
  background-color:  transparent !important; /*背景色完全透明*/
}
::v-deep .el-table__fixed-right::before {
  background-color:  transparent !important; /*背景色完全透明*/
}
</style>